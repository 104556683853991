<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-15 09:40:18
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-25 14:01:10
 * @FilePath: \mid-autumn\src\views\home\first\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index">
    <!-- 中部 -->
    <van-row class="main">
      <iframe
        v-if="countList == 0"
        src="https://720yun.com/t/44vk6eih57l?scene_id=99760960"
        frameborder="no"
        width="100%"
        height="100%"
      ></iframe>
      <iframe
        v-else-if="countList == 1"
        src="https://720yun.com/t/44vk6eih57l?scene_id=99760961"
        frameborder="no"
        width="100%"
        height="100%"
      ></iframe>
      <iframe
        v-else-if="countList == 2"
        src="https://720yun.com/t/44vk6eih57l?scene_id=99760962"
        frameborder="no"
        width="100%"
        height="100%"
      ></iframe>
      <iframe
        v-else
        src="https://720yun.com/t/44vk6eih57l?scene_id=99760963"
        frameborder="no"
        width="100%"
        height="100%"
      ></iframe>
      <van-button type="primary" class="firstButton" @click="upper()">上一层</van-button>
      <van-button type="primary" class="nextButton" @click="nexter()">下一层</van-button>
    </van-row>
    <!-- 尾部 -->
    <!-- <van-row class="footer">
      <div class="explore"></div>
    </van-row> -->
  </div>
</template>

<script>
import { Notify } from 'vant';
export default {
  data() {
    return {
      countList: 0,
    };
  },
  methods: {
    upper() {
      if (this.countList < 3) {
        this.countList += 1;
      } else {
        Notify({ type: 'danger', message: '已经到最高层了，再上就要上天了' });
      }
    },
    nexter() {
      if (this.countList != 0 && this.countList) {
        this.countList -= 1;
      } else {
        Notify({ type: 'danger', message: '已经到第一层了' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-image: none;

  .main {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow-y: scroll;
    position: relative;
  }

  .firstButton {
    width: 30%;
    height: 5%;
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    background-image: url('../../../assets/中秋0816/按钮 框.png');
    background-size: 100% 100%;
    background-position: center center;
    border: none;
  }

  .nextButton {
    width: 30%;
    height: 5%;
    position: absolute;
    top: 6%;
    right: 0;
    background: none;
    background-image: url('../../../assets/中秋0816/按钮 框.png');
    background-size: 100% 100%;
    background-position: center center;
    border: none;
  }

  .seconedButton {
    width: 100px;
    height: 100px;
    // border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    background: none;
    transform: translate(-50%, -50%);
    border: none;
    /* border: 1px solid #fff; */
    // background-image: url('../../../assets/中秋0816/按钮 框.png');
    // border: none;
    background-size: 100% 100%;
    background-position: center center;

    &.button1 {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.button3 {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.button2 {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .footer {
    width: 100%;
    height: 30%;
    /* background-image: url('../../../assets/中秋0816/探索进度底图.png'); */
    /* background-size: 100% 100%;
    background-position: center center; */
    background: none;
    position: relative;
    position: absolute;
    bottom: 0;

    .explore {
      width: 30%;
      height: 20%;
      margin: 12% auto;
      /* background-image: url('../../../assets/中秋0816/图层 10.png'); */
      /* background-size: 100% 100%;
      background-position: center center; */
      margin-left: 50%;
      transform: translateX(-50%);
      position: relative;

      img {
        width: 80%;
        height: 25%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .start-button {
      width: 60%;
      height: 20%;
      text-align: center;
      line-height: 60px;
      border-radius: 50px;
      position: absolute;
      bottom: 12%;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50px;
      background-image: url('../../../assets/中秋0816/按钮 框.png');
      background-size: 100% 100%;
      background-position: center center;

      .button {
        height: 100%;
        border-radius: 50px;
        background: none;
        font-size: 20px;
        border: none;
        letter-spacing: 10px;
      }
    }
  }

  .popup {
    width: 100%;
    height: 100%;
    background: url('../../../assets/中秋0816/遮照.png');
    background-size: 100% 100%;
    background-position: center center;

    .size {
      margin: 0 auto;
      width: 90%;
      height: 290px;
      background: url('../../../assets/中秋0816/beijing-框.png');
      background-size: 100% 100%;
      background-position: center center;
      text-align: center;
      margin-top: 60%;
      letter-spacing: 5px;

      .yellow {
        padding-top: 26%;
        color: #fff;
        font-size: 23px;
        font-weight: bold;
        line-height: 30px;

        span {
          display: inline-block;
          margin-top: 40px;
          font-size: 16px;
          color: #66ffff;
          letter-spacing: 4px;
        }
      }
    }

    .sure {
      font-size: 16px;
      width: 37%;
      height: 70px;
      background-image: url('../../../assets/中秋0816/进度框.png');
      background-size: 100% 100%;
      background-position: center center;
      position: absolute;
      top: 5%;
      right: 5%;

      .button {
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        letter-spacing: 10px;
      }
    }

    .continue {
      width: 250px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      border-radius: 50px;
      position: absolute;
      bottom: 12%;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50px;
      background-image: url('../../../assets/中秋0816/按钮 框.png');
      background-size: 100% 100%;
      background-position: center center;

      .button {
        height: 100%;
        /* margin-top: 10px; */
        border-radius: 50px;
        background: none;
        font-size: 21px;
        border: none;
      }
    }
  }
}

#container {
  height: 400px;
}
</style>
